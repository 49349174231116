import React from "react";
import {graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"
import DefaultLangSelector from "../components/LangSelector";
import data from '../../data.json';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import downloadSrc from '../images/svg/download.svg';
import arrowSrc from '../images/svg/arrow.svg';
import bgShadowSrc from '../images/bg-shadow.jpg';

const DownloadIcon = styled(downloadSrc)`
  width: 16px;
  height: auto;
  fill: #303030;

  &:hover {
    fill: #9a9a9a;
  }
`;

const ArrowIcon = styled(arrowSrc)`
  width: 12px;
  height: auto;
  fill: #303030;

  &:hover {
    fill: #9a9a9a;
  }
`;

const LangSelector = styled(DefaultLangSelector)`
  position: relative;
  top: auto;
  right: auto;
  margin-bottom: 50px;
  justify-content: center;

  @media(min-width: 768px) {
    margin-bottom: 20px;
    justify-content: flex-end;
  }
`;

const Content = styled.div`
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const LeftSubtitle = styled(Subtitle)`
  text-align: left;

  &:before {
    left: 0;
    transform: none;
  }
`;

const Section = styled.div`
  position: relative;
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;

  padding: 40px 1.5rem 110px;

  @media(min-width: 768px) {
    padding: 40px 70px 110px;
  }
`;


const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  /* padding-left: 30px; */
`;

const Td = styled.td`
  padding: 0.5rem 0.25rem;
  text-align: center;

  &.td-right {
    text-align: right;
  }

  @media (min-width: 1200px) {
      padding: 0.5rem;
    }

  @media (min-width: 1400px) {
    padding: 1rem;
  }
`;

const Tr = styled.tr``;

const Table = styled.table`
  width: 100%;
  margin-top: 60px;
  color: #303030;
  font-size: 0.875rem;

  .sm,
  .md {
    display: none;
  }

  thead tr th {
    text-transform: uppercase;
    text-align: center;
    background-color: #eaa548;
    color: #fff;
    padding: 0.5rem 0.25rem;
    word-break: break-word;
    font-size: 0.875rem;
    font-weight: 400;

    @media (min-width: 1200px) {
      padding: 0.5rem;
    }

    @media (min-width: 1400px) {
      padding: 1rem;
      font-weight: 700;
    }
  }

  tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  tbody tr:hover {
    background-color: #f4d2a3;
  }

  @media (min-width: 576px) {
    .sm {
      display: table-cell;
    }
  }

  @media (min-width: 768px) {
    .md {
      display: table-cell;
    }
  }

  @media (min-width: 992px) {
    font-size: 0.875rem;

    .md {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    .md {
      display: table-cell;
    }
  }

  @media (min-width: 1400px) {
    font-size: 1rem;
  }

`;

const PriceListPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('seo.pricelist.title')} />
      <Content>
        <Section>
          <ContentWrapper>
            <LangSelector isDark={true} />
            <LeftSubtitle as="h1">{t('pricelist.title')}</LeftSubtitle>
            <Table>
                    <thead>
                      <tr>
                        <th>{t('pricelist.table.id')}</th>
                        <th className="md">{t('pricelist.table.floor')}</th>
                        <th className="sm">{t('pricelist.table.state')}</th>
                        <th className="md">{t('pricelist.table.area')}</th>
                        <th className="sm">{t('pricelist.table.type')}</th>
                        <th>{t('pricelist.table.price')}</th>
                        <th>{t('pricelist.table.detail')}</th>
                        <th>{t('pricelist.table.download')}</th>
                      </tr>
                    </thead>
                    <tbody>
                        { data.map(d => {

                          const balcony = d.balcony?.value || 0;
                          const terrace = d.terrace?.value || 0;

                          const sum = d.sum_area+balcony+terrace+d.cellar;

                          return (
                            <Tr
                              key={d.id}
                              >
                              <Td>{d.id}</Td>
                              <Td className="md">{d.floor}.&nbsp;NP</Td>
                              <Td className="sm">{t(`state.${d.state}`)}</Td>
                              <Td className="md td-right">{new Intl.NumberFormat('cs-CZ').format(sum)}&nbsp;m<sup>2</sup></Td>
                              <Td className="sm">{d.type}</Td>
                              <Td className="td-right">{d.state !== 'available' ? '---' : new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(d.price)}</Td>
                              <Td>
                                <Link to={`/dum-${d.building.toLocaleLowerCase()}/${d.id.toLocaleLowerCase()}`}>
                                  <ArrowIcon />
                                </Link>
                              </Td>
                              <Td>
                                {
                                  d.state !== 'available' ? '' : (
                                    <a href={`/data/pdf/${d.id}.pdf`} target="_blank" rel="noreferrer">
                                      <DownloadIcon />
                                    </a>
                                  )
                                }

                              </Td>
                            </Tr>
                        )})
                      }
                    </tbody>
                  </Table>
          </ContentWrapper>
        </Section>
      </Content>
    </Layout>
  )
}

export default PriceListPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
